<template>
  <v-card class="text-center">
    <v-img
      v-if="content.type === 'VIDEO'"
      width="100%"
      height="60px"
      :src="content.thumbnail"
    >
      <v-app-bar flat color="rgba(0, 0, 0, 0)">
        <v-btn
          color="white"
          outlined
          icon
          x-small
          @click="$emit('viewContent', content._id)"
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>

        <v-spacer></v-spacer>

        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="form.finalDate"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="white" outlined x-small icon v-bind="attrs" v-on="on">
              <v-icon>mdi-calendar-check</v-icon>
            </v-btn>
          </template>
          <v-date-picker
            v-model="form.finalDate"
            locale="pt-br"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(form.finalDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <v-spacer></v-spacer>

        <v-btn
          color="white"
          icon
          x-small
          outlined
          @click="$emit('deleteContent', content)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-app-bar>
    </v-img>
    <v-img
      v-else-if="content.type === 'RSS'"
      width="100%"
      height="120px"
      src="../../../../public/img/RSS.png"
    >
      <v-app-bar flat color="rgba(0, 0, 0, 0)">
        <v-spacer></v-spacer>

        <v-btn color="white" icon @click="$emit('deleteContent', content)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-app-bar>
    </v-img>
    <v-img
      v-else-if="content.type === 'INSTAGRAM'"
      width="100%"
      height="120px"
      src="../../../../public/img/Instagram.jpg"
    >
      <v-app-bar flat color="rgba(0, 0, 0, 0)">
        <v-spacer></v-spacer>

        <v-btn color="white" icon @click="$emit('deleteContent', content)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-app-bar>
    </v-img>
    <span class="text-center font-weight-bold" style="font-size: 8px">
      {{ content.name }}
    </span>
    <v-spacer></v-spacer>
    <v-chip
      :color="color"
      text-color="white"
      v-if="content.finalDate"
      x-small
      >{{ content.finalDate }}</v-chip
    >
    <v-chip :color="color" text-color="white" v-else x-small
      >Sem data final</v-chip
    >
  </v-card>
</template>
<script>
import moment from "moment";
import ContentService from "../../contents/service";
export default {
  props: ["content"],
  data() {
    return {
      menu: false,
      form: {
        finalDate: "",
      },
      fileType: "",
      color: "white",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  async mounted() {
    moment.locale("pt-br");
    const initialDate = this.parseDate(this.content.initialDate);
    const finalDate = this.parseDate(this.content.finalDate);
    const now = new Date();

    if (finalDate && finalDate >= now && initialDate <= now) {
      this.color = "green";
      this.form.finalDate = moment(finalDate).format("YYYY-MM-DD");
    } else if (finalDate && finalDate < now) {
      this.color = "red";
    } else if (initialDate > now) {
      this.color = "blue";
    } else {
      this.color = "green";
    }
  },

  // watch: {
  //   "form.finalDate": {
  //     async handler(newVal) {
  //       this.content.finalDate = moment(newVal).format("DD/MM/YYYY");
  //       await this.updateContent();
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    parseDate(dateString) {
      if (!dateString) {
        return null;
      }
      return new Date(moment(dateString, "DD/MM/YYYY").format("YYYY-MM-DD"));
    },
    async updateContent() {
      try {
        const content = await ContentService.updateContent(
          { finalDate: this.content.finalDate },
          this.content._id
        );
        return content;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
