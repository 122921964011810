<template>
  <BaseDialog
    v-model="dialog"
    title="Inserir Conteúdo"
    @handlerSave="sendContentsToPlaylist"
    :isValid="isValid"
  >
    <CreateContent
      v-if="newContent"
      :component="true"
      @sendedNewContent="createdContent"
      @closeModal="newContent = false"
    ></CreateContent>
    <v-row justify="space-between">
      <v-col cols="auto">
        <v-btn-toggle
          v-model="filter"
          tile
          color="#35909D"
          group
          @change="handlerFilter"
        >
          <v-btn value="VIDEO"> VIDEO </v-btn>
          <v-btn value="RSS"> RSS </v-btn>
          <v-btn value="INSTAGRAM"> INSTAGRAM </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="2">
        <v-text-field
          label="Video:"
          filled
          rounded
          v-model="search"
          @input="searchVideos"
          dense
          clearable
          hide-details
          background-color="#F5F8FA"
          full-width
        ></v-text-field>
      </v-col>
      <v-col cols="auto">
        <v-btn
          color="#35909D"
          @click="newContent = true"
          dark
          fab
          tile
          small
          class="mr-4 rounded-button"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-divider class="mt-4 mb-4"></v-divider>

    <!-- GRID DOS ITENS SELECIONÁVEIS -->
    <v-row class="mb-5">
      <v-col cols="6" class="scrollable">
        <v-card-text class="text-subtitle-1 mt-n4">
          Dê duplo clique sobre o conteúdo para incluir na playlist.
        </v-card-text>
        <v-container
          id="scroll-target"
          style="max-height: 400px"
          class="overflow-y-auto"
        >
          <v-row style="width: 100%">
            <v-col
              cols="3"
              v-for="content in visibleContents"
              :search="search"
              :key="content._id"
            >
              <PlaylistContent
                @dbClick="contentSelected"
                @viewContent="viewContent"
                :content="content"
              />
            </v-col>
          </v-row>
          <v-row
            v-scroll:#scroll-target="onScroll"
            align="center"
            justify="center"
            style="height: auto"
          >
          </v-row>
        </v-container>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col cols="6" class="scrollablev2">
        <v-card-text class="text-subtitle-1 mt-n4">
          Conteúdos que serão incluídos na playlist (dê duplo clique para
          retirar):
        </v-card-text>
        <v-container
          id="scroll-target"
          style="max-height: 400px"
          class="overflow-y-auto"
        >
          <v-row style="width: 100%">
            <v-col
              cols="3"
              v-for="content in selectedContents"
              :search="search"
              :key="content._id"
            >
              <PlaylistContent
                @dbClick="contentRemoved"
                @viewContent="viewContent"
                :content="content"
              />
              <Count
                class="d-flex justify-center"
                @input="(i) => (content.repeatCounter = i)"
              />
            </v-col>
          </v-row>
          <v-row
            v-scroll:#scroll-target="onScroll"
            align="center"
            justify="center"
            style="height: auto"
          >
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-divider class="mt-4 mb-4"></v-divider>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-autocomplete
          label="Opções:"
          filled
          rounded
          v-model="videosOrder"
          :items="options"
          dense
          clearable
          hide-details
          background-color="#F5F8FA"
          full-width
        ></v-autocomplete>
      </v-col>
    </v-row>
    <BaseViewContent v-if="dialogViewContent" :content="contentView" />
    <BaseLoading v-if="dialogLoading" />
  </BaseDialog>
</template>
<script>
import PlaylistContent from "./PlaylistContent";
import contentService from "../../contents/service";
import CreateContent from "../../contents/create";
import BaseDialog from "../../../components/UI/BaseDialog";
import BaseViewContent from "../../../components/UI/BaseViewContent.vue";
import BaseLoading from "../../../components/UI/BaseLoading";
import Count from "../../../components/UI/Utility/Counter/PlusMinusField.vue";
export default {
  name: "PlaylistAddContent",
  props: ["contents"],
  data() {
    return {
      dialog: true,
      newContent: false,
      selectedContents: [],
      visibleContents: this.contents,
      filter: "VIDEO",
      isValid: false,
      isLoading: false,
      search: "",
      videosOrder: "Aleatório",
      options: ["Início", "Aleatório", "Final"],
      contentView: {},
      dialogLoading: false,
      dialogViewContent: false,
    };
  },
  components: {
    PlaylistContent,
    CreateContent,
    BaseDialog,
    BaseLoading,
    BaseViewContent,
    Count,
  },
  methods: {
    async createdContent() {
      this.newContent = false;
      this.$emit("refreshContents", { type: this.filter });
    },
    async sendContentsToPlaylist() {
      const request = {
        videosOrder: this.videosOrder,
        selectedContents: this.selectedContents,
      };
      console.log(request);
      this.$emit("getSelectedContents", request);
    },

    async handlerFilter(val) {
      this.$emit("refreshContents", { type: val });
    },
    async searchVideos() {
      if (!this.search) {
        this.visibleContents = this.contents;
        return;
      }
      this.visibleContents = this.contents.filter((content) => {
        return content.name.includes(this.search);
      });
    },
    async contentSelected(event) {
      this.selectedContents.push(event);
    },
    async contentRemoved(event) {
      const index = this.selectedContents.indexOf(event);
      this.selectedContents.splice(index, 1);
    },
    async createContent() {
      try {
        const content = await contentService.createContent();
        this.content = content.data;
      } catch (error) {
        console.error(error);
      }
    },
    async create() {
      try {
        const content = await contentService.create();
        this.create = content.data;
      } catch (error) {
        console.error(error);
      }
    },
    async viewContent(contentId) {
      try {
        console.log("entrou");
        this.dialogLoading = true;
        const content = await contentService.getContentById(contentId);
        this.contentView = content.data;
        this.dialogLoading = false;
        this.dialogViewContent = true;
      } catch (error) {
        console.error(error);
        this.dialogLoading = false;
      }
    },
  },
  watch: {
    selectedContents: {
      handler: async function (val) {
        if (val.length > 0) this.isValid = true;
      },
      deep: true,
    },
  },
};
</script>
