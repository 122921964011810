<template>
  <v-dialog v-model="dialog" width="50vw" @click:outside="onClickOutside">
    <v-card color="primary" dark>
      <video width="100%" controls="controls">
        <source :src="content.file" type="video/mp4" />
      </video>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["content"],
  data() {
    return {
      dialog: true,
    };
  },

  mounted() {
    if (this.content.file) {
      this.content.file =
        process.env.VUE_APP_AWS_CLOUDFRONT_URL +
        this.content.file.split(process.env.VUE_APP_AWS_S3_URL)[1];
    }
    console.log(this.content);
  },

  methods: {
    onClickOutside() {
      this.$emit("dialogClosed", true);
    },
  },
};
</script>
