<template>
  <BaseDialog
    v-if="dialogPlaylist"
    :fullscreen="true"
    transition="dialog-bottom-transition"
    :title="isEdit ? 'Editar Playlist' : 'Adicionar Playlist'"
    :isValid="isValid"
    :isLoading="isLoading"
    @handlerSave="create"
  >
    <v-row>
      <v-col cols="auto">
        <v-btn color="#35909D" dark class="mt-2" @click="dialogContents = true">
          Adicionar Conteudo/Rss
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="mt-5 mb-5"></v-divider>

    <v-row class="mb-4 pr-6">
      <v-col v-if="selectedContents.length > 0">
        <v-row>
          <v-col cols="12">
            <draggable
              style="
                display: flex;
                flex-wrap: wrap;
                flex: 1 1 auto;
                margin: -12px;
              "
              v-model="selectedContents"
              group="people"
              @start="drag = true"
              @end="drag = false"
            >
              <v-col
                cols="1"
                v-for="(content, index) in selectedContents"
                :key="index"
              >
                <PlaylistContents
                  @viewContent="viewContent"
                  @deleteContent="deleteContent"
                  :content="content"
                />
              </v-col>
            </draggable>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-else>
        <v-alert dense border="left" type="warning" class="mb-0">
          Nenhum conteudo adicionado ainda.
        </v-alert>
      </v-col>
    </v-row>
    <BaseViewContent
      v-if="dialogViewContent"
      :content="contentView"
      @dialogClosed="dialogViewContent = false"
    />
    <PlaylistAddContent
      v-if="dialogContents"
      @refreshContents="getContents"
      :contents="contents.docs"
      @getSelectedContents="getSelectedContents"
      @closeModal="dialogContents = false"
    />
    <BaseLoading v-if="dialogLoading" />
  </BaseDialog>
</template>
<script>
import service from "../terminal/service";
import contentService from "../contents/service";
import PlaylistAddContent from "./_components/PlaylistAddContent";
import PlaylistContents from "./_components/PlaylistContents";
import BaseDialog from "../../components/UI/BaseDialog.vue";
import BaseLoading from "../../components/UI/BaseLoading";
import BaseViewContent from "../../components/UI/BaseViewContent.vue";
import draggable from "vuedraggable";

export default {
  name: "Schedule",
  data() {
    return {
      isValid: false,
      isLoading: false,
      dialogLoading: false,
      isEdit: false,
      contentView: {},
      contents: [],
      selectedContents: [],
      form: {
        contents: [],
      },
      dialogContents: false,
      dialogPlaylist: true,
      dialogViewContent: false,
    };
  },

  components: {
    PlaylistAddContent,
    PlaylistContents,
    draggable,
    BaseDialog,
    BaseLoading,
    BaseViewContent,
  },

  async mounted() {
    try {
      this.dialogLoading = true;
      await this.getContents();
      this.isEdit = this.$route.name === "Schedule";
      if (this.isEdit) {
        const { data: terminal } = await service.getTerminalById(
          this.$route.params.id,
          { populated: true }
        );
        this.selectedContents = terminal.contents.map((element) => ({
          ...element,
          thumbnail: this.getCloudfrontUrl(element.thumbnail),
        }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.dialogLoading = false;
    }
  },

  methods: {
    async create() {
      try {
        const contentIds = this.selectedContents.map((element) => element._id);
        const form = { ...this.form, contents: contentIds };
        const data = await service.updateTerminal(form, this.$route.params.id);
        this.snackbar = true;
        this.$router.push({ name: "Terminal" });
        this.$emit("created", data);
      } catch (error) {
        console.error(error);
      }
    },

    async getContents(val) {
      try {
        const { data } = await contentService.getContents({
          pagination: false,
          ...val,
        });
        this.contents = {
          ...data,
          docs: data.docs.map(this.transformThumbnailUrl),
        };
      } catch (error) {
        console.error(error);
      }
    },
    transformThumbnailUrl(element) {
      if (element.thumbnail) {
        return {
          ...element,
          thumbnail: this.getCloudfrontUrl(element.thumbnail),
        };
      } else {
        return element;
      }
    },
    getCloudfrontUrl(s3Url) {
      if (this.isValidUrl(s3Url)) {
        const s3UrlPath = new URL(s3Url).pathname;
        const cloudfrontUrl = process.env.VUE_APP_AWS_CLOUDFRONT_URL;
        const s3UrlPrefix = process.env.VUE_APP_AWS_S3_URL;
        return `${cloudfrontUrl}${s3UrlPath.replace(s3UrlPrefix, "")}`;
      }
    },

    isValidUrl(url) {
      try {
        new URL(url);
        return true;
      } catch (e) {
        return false;
      }
    },

    async contentSelected(event) {
      this.selectedContents.push(event);
    },
    async showMenu(event) {
      this[event] = true;
    },
    async getSelectedContents(videos) {
      const newContents = [];
      let midIndex = [];

      videos.selectedContents.forEach(
        ({ repeatCounter, ...selectedContent }) => {
          for (let i = 0; i < repeatCounter; i++) {
            newContents.push(selectedContent);
          }
        }
      );

      switch (videos.videosOrder) {
        case "Início":
          this.selectedContents.unshift(...newContents);
          break;
        case "Final":
          this.selectedContents.push(...newContents);
          break;
        case "Aleatório":
          midIndex = Math.round(this.selectedContents.length / 2);
          this.selectedContents.splice(midIndex, 0, ...newContents);
          break;
        default:
          console.warn(`Unexpected videosOrder value: ${videos.videosOrder}`);
      }

      this.dialogContents = false;
    },

    async viewContent(contentId) {
      try {
        this.dialogLoading = true;
        const content = await contentService.getContentById(contentId);
        this.contentView = content.data;
        this.dialogLoading = false;
        this.dialogViewContent = true;
      } catch (error) {
        console.error(error);
        this.dialogLoading = false;
      }
    },
    async deleteContent(content) {
      try {
        this.dialogLoading = true;
        const index = this.selectedContents.indexOf(content);
        this.selectedContents.splice(index, 1);
      } catch (error) {
        console.error(error);
      } finally {
        this.dialogLoading = false;
      }
    },
  },

  watch: {
    selectedContents: {
      handler() {
        this.isValid = this.selectedContents.length > 0;
      },
      deep: true,
    },
  },
};
</script>
