<template>
  <v-dialog v-model="dialogLoading" persistent width="300">
    <v-card color="#35909D" dark>
      <v-card-text>
        Carregando conteúdos, aguarde!
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialogLoading: true,
    };
  },
};
</script>
